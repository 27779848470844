/*.readpost-container {
  display: grid;
  grid-template-columns: auto minmax(auto, 700px) auto;
  justify-content: center;
  background-color: #f1f1f1;
  grid-row-gap: 10px;
}*/

.read-post {
  grid-column: 2;
  flex-wrap: wrap;
  background-color: #ffffff;
  padding: 0 50px 50px 50px;
  font-size: 120%;
  line-height: 200%;
  margin-bottom: 30px;
  height: fit-content;
}

.post-voter {
  size: 150%;
  grid-column: 2;
  grid-row: 2;
  background-color: #ffffff;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
}

.voter-button-up{
  background: linear-gradient(to right, #00c1cd, #0085cd);
}

.voter-button-down{
  background: linear-gradient(to right, #FFB137, #FF8D36);
}

.post-reply-button{
  margin-top: 5px;
  grid-row: 6;
  background-color: #ffffff;
}

.comments {
  grid-column: 2;
  grid-row: 4;
  background-color: #ffffff;
  padding: 20px;
  width: 100%;
  margin-bottom: 30px;
}

.comment-header {
  display: flex;
  width: 100%;
}

.comment-title {
  width: 100%;
}

.comment-time-posted {
  font-style: italic;
  float: right;
}

.comment-highlighted {
  border: 1px dashed black;
}

.readpost-container {
  display: grid;
  grid-template-columns: 100%;
}

.readpost-post-is-reply {
  grid-row: 1;
}

.readpost-title {
  grid-row: 2;
  text-align: center;
}

.readpost-author-line {
  height: 100px;
  margin: 5px 0;
  text-align: left;
  background: #f5f5f5;
}

.readpost-author-avatar {
  border-radius: 50%;
  height: 90px;
  width: 90px;
  margin: 5px;
}

.readpost-timestamp {
  float: right;
  font-size: 100%;
  font-style: italic;
  margin: 5px;
}

.readpost-author-line *{
  margin-left: 10px;
  display: inline-block;
  color: black;
}

.readpost-author-name {
}

.readpost-author-name:hover {
  text-decoration: underline;
}

.readpost-body {
  grid-row: 3;
  word-wrap: break-word;
}

.readpost-body img{
  object-fit: cover;
  max-width: 100%;
  text-align: center;
}

.readpost-body iframe{
  object-fit: cover;
  height: auto;
  max-width: 90% !important;
  max-height: 1000px;
}

.readpost-body html{
  font-size: inherit;
}

.readpost-tags {
  grid-row: 4;
}

.readpost-tag {
  float: left;
  padding: 10px;
  font-weight: bold;
}

.readpost-bottom-bar {
  grid-row: 5;
  grid-column: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
}

.readpost-votes {
  grid-row: 1;
  grid-column: 1;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 9fr;
  grid-column-gap: 2px;
}

.readpost-votes *{
  display: inline-block;
}

.readpost-vote-item{
  padding: 0;
  border: none;
  margin: 0;
  height: 4vh;
  width: 4vh;
  grid-column: 1;
}

.readpost-vote-item * {
  width: 100%;
}

.readpost-vote-amount {
  grid-column: 2;
  cursor: pointer;
}

.readpost-payout-value {
  grid-row: 1;
  grid-column: 2;
}

.readpost-comment-container {
  position: relative;
  min-width: 150px;
  margin-right: 0;
}

.readpost-comment-container img {
  max-width: 100%;
}

.comment-author-avatar {
  height: 30px;
  width: 30px;
  margin-right: 5px;
}

.readpost-comment-body {
  word-wrap: break-word;
}

.readpost-comment-bottom-bar {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
}

.readpost-comment-votes {
  grid-row: 1;
  grid-column: 1;
  margin-bottom: 5px;
  display:grid; 
  grid-template-columns: 1fr 9fr;
  grid-column-gap: 2px;
}

.readpost-comment-vote-amount {
  cursor: pointer;
}

.readpost-comment-payout-value {
  grid-row: 1;
  grid-column: 2;
}

.readpost-comment-votes * {
  display: inline-block;
}

.readpost-write-reply-container {
  background-color: inherit;
  position: relative;
  overflow: auto;
  max-width: 600px;
  border: 0px solid;
  box-shadow: 0px 0px 0px;
}

.continue-comment-section {
  position: relative;
  word-wrap: break-word;
  margin-top: -15px;
  margin-bottom: 15px;
}