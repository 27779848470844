.wallet-page {
    min-height: 100vh;
    background: rgb(228,228,228);
}

.wallet-container {
    width: 80%;
    margin: 0 auto;
}

.wallet-container>* {
  margin: 0 auto;
  width: fit-content;
}

.wallet-header {
  padding: 20px 0;
}

.wallet-information-container {

}

.wallet-user-avatar-container {
  height: 120px;
  width: 120px;
}

.wallet-user-avatar-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
