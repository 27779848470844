.app-links-page {
  background-color: rgb(228,228,228);
  min-height: 100vh;
  text-align: center;
}

.app-links-header {
  margin: 5px auto;
  width: 90%;
  max-width: 600px;
}

.app-links-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.app-links-container>* {
  display: inline-block;
}

.android-app-link img {
  max-width: 100%;
}

.ios-app-link img {
  margin-top: 40px;
  max-width: 100%;
  max-height: 170px;
}