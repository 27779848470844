.profile-page {
  background-color: rgb(228,228,228);
  min-height: 100vh;
}

.profile-columns {
  display: grid;
  grid-template-columns: 100%;
  background-color: rgb(228,228,228);
  margin: 0 auto;
}

.profile-fixed-container {
  width: 100%;
  position: fixed;
  display: grid;
  grid-template-columns: 2fr 600px 1fr;
  background: transparent;
  top: 255px;
}

.profile-fixed-container>* {
  margin-left: calc(100% - 300px)
}

.profile-banner-image {
  width: 100%;
  height: 200px;
  grid-row: 1;
  grid-column: 1;
  background: rgb(228,228,228);
  z-index: 4;
  object-fit: cover;
}

#feed-shared-posts-checkbox, label[for=feed-shared-posts-checkbox] {
  cursor: pointer;
}


@media only screen and (max-width: 767px) {

  .profile-banner-image {
    min-height: 100px;
    object-fit: fill;
  }
}

.profile-follow-button {
  grid-column: 2;
  grid-row: 1 / span 3;
  margin: auto;
}

.follow-button-spinner {
  border-width: 5px;
}


.profile-info-container {
  grid-row: 1;
  grid-column: 1/ span 2;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 300px 1fr 600px;
}



.profile-info {
  grid-template-columns: auto;
  grid-gap: 5px;
  display: grid;
  grid-row: 1;
  grid-column: 2;
  word-wrap: break-word;
  max-width: 300px;
  background: white;
  padding: 20px;
}

.profile-fixed-container, .profile-info-container, .profile-info {
  pointer-events: none;
}

.profile-info * {
  pointer-events: auto;
}

.profile-picture-container {
  height: 128px;
  width: 128px;
  grid-row: 1;
  grid-column: 1;
  margin: 0 auto;
}

.profile-picture {
  margin: 0 auto;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile-title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  grid-row: 2;
  grid-column: 1;
}

.profile-about {
  grid-row: 3;
  grid-column: 1;
  word-wrap: break-word;
}

.profile-about * {
  word-wrap: break-word;
}

.profile-other-info {
  grid-row: 4;
  grid-column: 1;
  z-index: inherit;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  margin-left: 5px;
}

.profile-other-info-box {
  text-align: left;
}

.profile-feed {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: 2fr 600px 1fr;
  margin-top: 10px;
}

.feed-outer-container-profile {
  grid-row: 2;
  grid-column: 2;
  background: #ddd;
  min-height: 70vh;
}


@media only screen and (max-width: 768px) {
  .profile-columns {
    grid-template-columns: 1fr;
  }

  .profile-feed {
    display: block !important;
    grid-row: 3;
    grid-column: 1;
  }

  .profile-fixed-container {
    position: static !important;
    grid-row: 2;
    grid-column: 1;
    display: grid;
    grid-template-columns: 1fr 2fr;
    background: white;
  }

  .profile-info {
    grid-row: 1;
    grid-column: 1/ span 2;
  }

  .profile-other-info {
    grid-row: 3;
    grid-column: 2;
    display: block;
  }

  .profile-banner-image {
    grid-row: 1;
    grid-column: 1;
  }

  .feed-fixed-container-profile {
    position: unset !important;
    text-align: right;
    left: 0;
  }
}

@media only screen and (max-width: 1060px)  {
  .feed-outer-container-profile {
    grid-column: 2;
  }

  .profile-feed {
    grid-template-columns: 300px 1fr;
  }
}